export const colors = {
  amaranth_purple: "#A40E4C",
  amaranth_purple_00: "#A40E4C00",
  amaranth_purple_35: "#A40E4C59",
  amaranth_purple_70: "#A40E4Cb3",
  space_cadet: "#2C2c54",
  space_cadet_00: "#2C2c5400",
  space_cadet_35: "#2C2c5459",
  space_cadet_70: "#2C2c54b3",
  cambridge_blue: "#9EBC9F",
  cambridge_blue_00: "#9EBC9F00",
  cambridge_blue_35: "#9EBC9F59",
  cambridge_blue_70: "#9EBC9Fb3",
  peach_puff: "#f5d6ba",
  peach_puff_00: "#f5d6ba00",
  peach_puff_35: "#f5d6ba59",
  peach_puff_70: "#f5d6bab3",
  atomic_tangerine: "#f49d6e",
  atomic_tangerine_00: "#f49d6e00",
  atomic_tangerine_35: "#f49d6e59",
  atomic_tangerine_70: "#f49d6eb3",
  cosmic_latte: "#faf7e9",
  cosmic_latte_00: "#faf7e900",
  cosmic_latte_35: "#faf7e959",
  cosmic_latte_70: "#faf7e9b3",
  kombu_green: "#273c2c",
  kombu_green_00: "#273c2c00",
  kombu_green_35: "#273c2c59",
  kombu_green_70: "#273c2cb3",
  off_gray: "#7d7d7d",
};
